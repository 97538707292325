import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  Alert: {
    fontWeight: "Bold",
    fontSize: "12pt",
    color: "red",
    textAlign: "center"
  }
});

class ErrorAlert extends PureComponent {
  render() {
    const { classes, children, visible } = this.props;
    return (
      <Typography
        variant="h4"
        gutterBottom
        className={classes.Alert}
        style={{ display: visible ? "block" : "none" }}
      >
        {children}
      </Typography>
    );
  }
}

ErrorAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired
};

export default withStyles(styles)(ErrorAlert);
